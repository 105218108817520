import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				KnowledgeBridge
			</title>
			<meta name={"description"} content={"Мрієте розпочати власну справу, але не знаєте, з чого почати? Чи, можливо, ви хочете вдосконалити свої знання, щоб вивести існуючий бізнес на новий рівень? У KnowledgeBridge ми допомагаємо отримати знання, навички та підтримку, які потрібні для успіху в сучасному світі бізнесу."} />
			<meta property={"og:title"} content={"KnowledgeBridge"} />
			<meta property={"og:description"} content={"Мрієте розпочати власну справу, але не знаєте, з чого почати? Чи, можливо, ви хочете вдосконалити свої знання, щоб вивести існуючий бізнес на новий рівень? У KnowledgeBridge ми допомагаємо отримати знання, навички та підтримку, які потрібні для успіху в сучасному світі бізнесу."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<meta name={"msapplication-TileColor"} content={"#0CAD1C"} />
		</Helmet>
		<Components.Header12>
			<Override slot="text" />
		</Components.Header12>
		<Section padding="60px 0 60px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-5">
			<Text
				margin="0px 0px 90px 0px"
				font="--headline1"
				color="--darkL1"
				border-color="--color-dark"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
			>
				<Strong>
					Програма курсу
				</Strong>
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				lg-padding="0px 0 0px 0"
				flex-direction="column"
				align-self="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						align-items="center"
						justify-content="center"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							1
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						border-width="0 0 0 1px"
						border-style="solid"
						border-color="#c8ced7"
						padding="0px 0px 0px 20px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							<Strong>
								Вступ до основ бізнесу
							</Strong>
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 30px 0px"
						>
							- Підприємницьке мислення
							<br />
							<br />
							- Пошук можливостей і подолання викликів
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							2
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Дослідження ринку та аналіз клієнтів
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 30px 0px"
						>
							- Інструменти для аналізу аудиторії
							<br />
							<br />
							- Як створити портрет клієнта
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						z-index="100"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							3
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Розробка бізнес-моделі
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 30px 0px"
						>
							- Дизайн і розробка продукту
							<br />
							- Як створити цінність для клієнта
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						z-index="100"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							4
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Створення продукту або послуги
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 30px 0px"
						>
							- Дизайн і розробка продукту
							<br />
							<br />
							- Як створити цінність для клієнта
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						z-index="100"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							5
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Маркетинг і просування
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 30px 0px"
						>
							- Стратегії маркетингових кампаній
							<br />
							<br />
							- Як залучати перших клієнтів
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						z-index="100"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							6
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Фінанси і управління
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 30px 0px"
						>
							- Основи фінансового управління
							<br />
							<br />
							- Як організувати бюджет і прогнозування
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 0 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						z-index="100"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							7
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Зростання та масштабування бізнесу
						</Text>
						<Text
							margin="0 0 30px 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							- Як масштабувати бізнес
							<br />
							<br />
							- Стратегії зростання та розширення
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark" background="--color-lightD1">
			<Text as="h1" font="--headline1" margin="0 0 24px 0">
				<Strong>
					Як приєднатися до курсу?
				</Strong>
			</Text>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline4" margin="12px 0">
							Зателефонуйте нам за номером 0970936624
						</Text>
					</Box>
				</Box>
				<Box width="33.333%" padding="16px 16px 16px 16px" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline4" margin="12px 0">
							Оберіть план навчання
						</Text>
					</Box>
				</Box>
				<Box width="33.333%" padding="16px 16px 16px 16px" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline4" margin="12px 0">
							Розпочніть шлях до успішної побудови бізнесу!
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer12>
			<Override slot="link4">
				0970936624
			</Override>
		</Components.Footer12>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"614ce40335c5c8001f7746de"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});